.MakeOfferInfo h5 {
  font-size: 13px;
  font-weight: 500;
  color: #fff7;
  line-height: 16px;
  background: #00205c;
  padding: 8px 15px 0px;
  width: max-content;
  margin-bottom: 10px;
}

.MakeOfferInfo h5 span {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin-top: 5px;
}

.swal2-container {
  z-index: 999999;
}

.MakeOfferInfo h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.MakeOfferInfo p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.ref-boxes {
  display: flex;
  gap: 10px;
}

.ref-boxes h5 {
  font-size: 16px;
}

.pb-3-custom {
  height: 62px !important;
}

.MakeOfferInfo p a {
  color: #00205c;
  font-weight: 700;
}
.input-groupWrap {
  display: flex;
  background: #fff;
  width: 100%;
  height: 45px;
  border: 1px solid #ced4da;
  margin-bottom: 15px;
}

.input-group {
  max-width: 780px;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
}

.input-group input {
  width: 100%;
}

.input-groupWrap .input-group-text {
  background: transparent;
  border: navajowhite;
  font-weight: 700;
  color: #043289;
}

.input-groupWrap input {
  border: none;
  padding: 0;
}

.input-group .btn.btn-blue {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.RedAlert {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 10px;
  border-radius: 0;
}

.notice-container {
  width: 70%;
}

.notice {
  font-size: 9px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.offer-btn {
  height: 40px !important;
  gap: 5px;
}

.notice-icon {
  width: 16px;
  height: 15px;
}

.img-box {
  display: flex;
  gap: 5px;
  /* align-items: baseline; */
}

.main-container {
  justify-content: space-between;
}

.offer-footer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .notice {
    font-size: 8px;
    width: 100%;
  }

  .d-flex {
    /* flex-direction: row !important; */
  }
  .img-box {
    flex-direction: row !important;
  }

  .offer-box-u {
    display: flex !important;
    flex-direction: column !important;
  }

  .address-input-2 {
    width: 260px !important;
  }

  .main-container {
    display: flex;
    flex-direction: row !important;
  }

  .notice-container {
    width: 100%;
    padding: 5px;
  }

  .offer-footer {
    flex-direction: row !important;
  }
}
