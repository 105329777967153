.my-modal .modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 1200px;
}
.scroll70 {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card {
  height: auto !important;
}

.scroll60 {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll50 {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll40 {
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll30 {
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll20 {
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll0 {
  max-height: 10vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

h6 {
  font-size: 14px;
}

.list-group-item {
  padding: 0 !important;
}

.postal > .list-group-item > .item_detail {
  padding: 8px;
  cursor: pointer;
}

.postal .list-group-item :hover {
  background-color: #043289 !important;
  color: white !important;
}

.blue-background {
  background-color: #043289 !important;
  color: white !important;
}

.custom-date-picker {
  border: 2px solid #007bff; /* Change the border color */
  padding: 10px; /* Add padding */
  font-size: 16px; /* Increase the font size */
  border-radius: 5px; /* Round the corners */
  background-color: #f8f9fa; /* Change the background color */
  color: #495057; /* Change the text color */
}

/* Customize the date picker when it's focused */
.custom-date-picker:focus {
  border-color: #0056b3; /* Change the border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a box shadow */
}

/* Customize the date picker placeholder */
.custom-date-picker::placeholder {
  color: #6c757d; /* Change the placeholder text color */
}

@media (max-width: 991px) {
  .card {
    align-items: normal !important;
  }
}
