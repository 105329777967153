.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.ant-tag {
  margin-bottom: 8px;
}

.rentTag {
  position: absolute;
  bottom: 10px;
  right: 110px;
  z-index: 9;
  background-color: purple;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  padding: 2px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 120px;
  cursor: pointer;
}

a.OpenAsPopup {
  pointer-events: none;
  cursor: pointer;
}

.ProductInfo h1 {
  font-size: 18px;
}

.product-flex {
  display: flex;
  justify-content: center;
  gap: 1px;
}

.ProductImg {
  cursor: pointer;
}
a.btn.btn-blue.btnFullCart {
  /* width: calc(100% - 0px); */
}

/* New Card Design */

.buynowButton {
  width: 100% !important;
}

.ProductCardItem {
  /* width: 380px !important; */
  /* height: 590px; */
  /* background-color: green !important; */
  /* max-width: 350px; */
  border-radius: 20px;
}

.productcard-img {
  width: 100%;
  height: 250px !important;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.title_link {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-wrap: wrap;
}

.indsutryname-productcard {
  margin-top: 10px;
  font-size: 14px !important;
  color: grey !important;
  font-weight: light !important;
}

.salePrice-productcard {
  font-size: 28px !important;
  font-weight: bold !important;
  color: #043289 !important;
}

.productcard-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100% !important;
  gap: 10px;
}

.btnFullCart .btnFullBuyNow {
  width: 50% !important;
}

.btnFullCart {
  background-color: transparent;
  border: 1px solid #043289;
  color: #043289;
  /* border-radius: px; */
}

.btnFullBuyNow {
  background-color: transparent !important;
  color: #043289 !important;
  border: 1px solid #043289 !important;
  /* border-radius: px; */
}

.btnFullBuyNow:hover {
  background-color: #043289 !important;
  color: #fff !important;
}

.img-fluid {
  position: relative;
}

.new-arrival-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #b7eb8f; /* Semi-transparent red background */
  color: #397430;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}

.viewMoreButton {
  background-color: #043289 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  margin: 0 auto;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure the container takes the full width */
  margin-top: 20px; /* Optional: Add some margin if needed */
}

.viewMoreButton {
  /* Add any additional styling for the button here */
}
