.black-custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  margin: 15px 0;
}

.black-custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.black-custom-checkbox span {
  width: 18px;
  height: 18px;
  border: 2px solid #0003;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 0;
}

.black-custom-checkbox p {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 0 8px;
}

.black-custom-checkbox input:checked + span {
  background: #000;
  font-size: 16px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .prompt-btn {
    display: flex;
    gap: 10px;
    background-color: yellow;
  }
}

@media (max-width: 767px) {
  .black-custom-checkbox p {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .scroll70.px-2 > .row > .col-3.p-0 {
    width: 100%;
  }

  .col-9.p-0 > div > .container > .row > .col {
    padding: 0;
    margin-top: 10px;
  }

  .scroll70.px-2 > .row > .col-9.p-0 {
    width: 100%;
  }
}
